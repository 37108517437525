import React from 'react';
import PropTypes from 'prop-types';
import styles from './popup.module.css';
import {PointerArea} from '../Pointer';

export default class Popup extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        isOpen: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    };
    static defaultProps = {
        isOpen: false,
    };

    render() {
        if (!this.props.isOpen) return null;

        return (
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    {this.props.children}
                </div>
                <PointerArea
                    type="link"
                    className={styles.close}
                    onClick={this.props.onClose}
                >
                    <div className={styles.inner}>
                        
                    </div>
                </PointerArea>
            </div>
        );
    }
}
