import styles from './mapStyles';

export default {
    info: {
        title: 'Contact Us',
        country: 'UAE',
        city: 'Dubai',
        street: 'Marasi Drive 1906-045',
        email: 'eldar@blackpolygon.dev',
    },
};
