import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import styles from './siteMapMenu.module.css';
import LinkBG from '../../components/LinkBG';
import Link from '../../components/Link';

class SiteMapMenu extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })),
        onMouseOver: PropTypes.func,
        onMouseOut: PropTypes.func,
        onClick: PropTypes.func,
        history: PropTypes.object.isRequired,
    };

    onLinkClick = (e, item) => {
        e.preventDefault();

        setTimeout(() => {
            this.props.history.push(item.path);
        }, 700);
    };

    render() {
        const {onMouseOver, onMouseOut, items} = this.props;
        
        return (
            <nav className={styles.siteMapMenu}>
                {items.map(item => {
                    const Component = item.image === undefined ? Link : LinkBG;

                    return (
                        <div
                            key={item.id}
                            className={styles.item}
                            onClick={this.props.onClick}
                        >
                            <Component
                                className={styles.link}
                                to={item.path}
                                onMouseOver={() => onMouseOver(item.id)}
                                onMouseOut={() => onMouseOut(item.id)}
                                onClick={(e) => this.onLinkClick(e, item)}
                            >
                                {item.name}
                            </Component>
                        </div>
                    );
                })}
            </nav>
        );
    }
}

export default withRouter(SiteMapMenu);
