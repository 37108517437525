import React from 'react';
import Popup from './Popup';
import PopupInjector, {set, unset} from './PopupInjector';

export {Popup, PopupInjector};

export default class PopupClient extends React.Component {
    componentDidMount() {
        set(this.props);
    }

    componentWillReceiveProps(nextProps) {
        set(nextProps);
    }

    componentWillUnmount() {
        unset();
    }

    render() {
        return null;
    }
}
