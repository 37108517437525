import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './siteMap.module.css';
import SiteMapMenu from '../SiteMapMenu';
import ImagesForMenuItems from '../ImagesForMenuItems';
import Tagline from '../Tagline';

export default class SiteMap extends React.Component {
    static propTypes = {
        tagline: PropTypes.object.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            image: PropTypes.string,
        })).isRequired,
        isOpen: PropTypes.bool,
        onItemClick: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        
        this.state = {
            hoveredMenuItemId: null,
        };
    }

    mouseOutTimer = null;

    onMenuItemMouseOver = id => {
        clearTimeout(this.mouseOutTimer);
        this.setState({hoveredMenuItemId: id});
    };
    onMenuItemMouseOut = () => {
        this.mouseOutTimer = setTimeout(() => {
            this.setState({hoveredMenuItemId: null});
        }, 300);
    };

    render() {
        const {items, tagline, isOpen} = this.props;

        const hoveredItem = items.find(item => item.id === this.state.hoveredMenuItemId);
        const image = hoveredItem === undefined ? undefined : hoveredItem.image;

        const isColorsInverted = image !== undefined;

        return (
            <div className={cn(styles.wrapper, {
                [styles.isOpen]: isOpen !== undefined && isOpen,
                [styles.isClosed]: isOpen !== undefined && !isOpen,
                [styles.isInverted]: isColorsInverted,
            })}>
                <div className={styles.content}>
                    <Tagline
                        content={tagline}
                        isOpen={isOpen}
                        isInverted={isColorsInverted}
                    />
                    <ImagesForMenuItems image={image} />
                    <SiteMapMenu
                        items={items}
                        onMouseOver={this.onMenuItemMouseOver}
                        onMouseOut={this.onMenuItemMouseOut}
                        onClick={this.props.onItemClick}
                    />
                </div>
            </div>
        );
    }
}