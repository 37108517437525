import tagline from './tagline';
import index from './index/index.js';
import works from './works';
import about from './about';
import contacts from './contacts';

import menuItemImageWorks from './works/menu-image.jpg';
import menuItemImageTeam from './about/menu-image.jpg';
import menuItemImageContacts from './contacts/menu-image.jpg';

export default {
    pages: {
        index: {
            title: 'Black Polygon',
            content: index,
        },
        works: {
            title: 'Black Polygon - Works',
            content: works,
        },
        about: {
            title: 'Black Polygon - About',
            content: about,
        },
        contacts: {
            title: 'Black Polygon - Contacts',
            content: contacts,
        },
    },

    menu: {
        tagline: tagline,
        items: [
            {
                id: 'works',
                path: '/works',
                name: 'Works',
                image: menuItemImageWorks,
            },  {
                id: 'contacts',
                path: '/contacts',
                name: 'Contacts',
                image: menuItemImageContacts,
            },
        ],
    },

    secondaryMenu: {
        items: [
          {
                id: 'contacts',
                name: 'Contacts',
                filters: [
                    /^\/contacts/,
                    /^\/about/,
                    /^\/works\/.+/,
                ],
            }, {
                id: 'works',
                name: 'Works',
                filters: [
                    /^\/contacts/,
                    /^\/about/,
                    /^\/works/,
                ],
                detached: true,
            },
        ],
    },
    
    liquidAnimationText: {
        title: "Don't worry and wait a bit.",
        text: "The best winners qualities - skill of sitting in ambush.",
    }
};
