import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './imagesForMenuItems.module.css';
import MaskedImage from './MaskedImage';

export default class ImagesForMenuItems extends React.Component {
    static propTypes = {
        image: PropTypes.string,
    };

    state = {
        prevImage: undefined,
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            prevImage: this.props.image,
        });
    }

    render() {
        const {image} = this.props;
        const {prevImage} = this.state;

        return(
            <div className={styles.imagesForMenuItems}>
                {(image !== prevImage && prevImage !== undefined) && (
                    <div
                        key={prevImage}
                        className={cn(styles.imageWrapper, {[styles.isUnactive]: image === undefined})}
                    >
                        <MaskedImage
                             image={prevImage}
                             className={styles.image}
                        />
                    </div>
                )}
                {(image !== undefined) && (
                    <div
                        key={image}
                        className={cn(styles.imageWrapper)}
                    >
                        <MaskedImage
                             image={image}
                             className={styles.image}
                        />
                    </div>
                )}
            </div>
        );
    }
}