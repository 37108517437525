import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from '../Link';
import LinkBG from '../LinkBG';
import styles from './menu.module.css';
import { withRouter } from 'react-router-dom';

class Menu extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })),
        isSiteMap: PropTypes.bool,
        onMouseOver: PropTypes.func,
        onMouseOut: PropTypes.func,
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    
    render() {
        const {items} = this.props;

        const isVisible = item => {
            if (!Array.isArray(item.filters)) return true;
            for (const filter of item.filters) {
                if (filter.test(this.props.location.pathname)) return false;
            }
            return true;
        };
        const visibleItems = items.map(item => ({
            ...item,
            isVisible: isVisible(item),
        }));
        
        return (
            <nav className={styles.menu}>
                {visibleItems.map((item) => item.detached ? (
                    <Link
                        className={cn(styles.link, styles.detached, {
                            [styles.hidden]: !item.isVisible,
                        })}
                        to={`/${item.id}`}
                        key={item.id}
                    >{item.name}</Link>
                ) : (
                    <LinkBG
                        className={cn(styles.link, {
                            [styles.hidden]: !item.isVisible,
                        })}
                        to={`/${item.id}`}
                        key={item.id}
                    >{item.name}</LinkBG>
                ))}
            </nav>
        );
    }
}

export default withRouter(Menu);