import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {PointerArea} from './Pointer';

export default class Link extends React.Component {
    render() {
        return (
            <PointerArea
                {...this.props}
                type="link"
                component={RouterLink}
            />
        );
    }
}
