import React from 'react';
import Popup from './Popup';

let instance = null;

export const set = props => {
    if (instance !== null) {
        instance.set(props);
    }
};
export const unset = () => {
    if (instance !== null) {
        instance.unset();
    }
};

export default class PopupInjector extends React.Component {
    constructor(props) {
        super(props);

        if (instance !== null) throw new Error('Only one instance of PopupInjector can be rendered');

        this.state = {
            popup: null,
        };

        instance = this;
    }

    set(props) {
        this.setState({
            popup: props,
        });
    }
    unset() {
        this.set(null);
    }

    render() {
        if (this.state.popup === null) return null;

        return (
            <Popup {...this.state.popup} />
        );
    }
}
