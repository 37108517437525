import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './slider.module.css';
import {PointerArea} from '../Pointer';
import Slide from './Slide';
import Dots from './Dots';

export default class Slider extends React.Component {
    static propTypes = {
        groups: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            slides: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string.isRequired,
                image: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                details: PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    text: PropTypes.string.isRequired,
                    background: PropTypes.string.isRequired,
                    images: PropTypes.arrayOf(PropTypes.string.isRequired),
                }).isRequired,
            })),
        })).isRequired,
        watchButtonText: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        
        this.state = {
            sliderIndex: 0,
            openingSlide: null,
        };
        
        this.slider = React.createRef();
        this.content = React.createRef();

        this.items = this.getSlides(props);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.groups !== nextProps.groups) {
            this.items = this.getSlides(nextProps);
        }
    }

    getSlides(props = this.props) {
        return this.props.groups.reduce((slides, group) => [
            ...slides,
            ...group.slides.map(slide => ({
                ...slide,
                groupId: group.id,
            })),
        ], []);
    }
    
    onPrevClick = () => {
        this.moveToSlide((this.state.sliderIndex - 1 + this.items.length) % this.items.length);
    };
    
    onNextClick = () => {
        this.moveToSlide((this.state.sliderIndex + 1) % this.items.length);
    };
    
    moveToSlide = index => {
        this.setState({sliderIndex: index}, function() {
            this.slider.current.style.setProperty('--slider-slide-number', index);
        });
    };

    onSlidesRef = (slides) => {
        this.slides = slides;
        
        if (slides !== null) {
            let isDragging = false;
            let initialPosition;
            let offset = 0;
            const onStart = (e) => {
                isDragging = true;
                initialPosition = e.touches[0].screenX;
                this.content.current.style.transition = 'none';
            };
            const onMove = (e) => {
                if (!isDragging) return;
                    
                offset = e.touches[0].screenX - initialPosition;
                
                this.slides.style.setProperty('--slider-drag-offset', offset);
            };
            const onStop = () => {
                if (!isDragging) return;
                isDragging = false;
                let slide = this.state.sliderIndex - Math.round(offset * 2 / this.slides.offsetWidth);
                slide = Math.max(0, Math.min(this.items.length - 1, slide));
                offset = 0;
                
                this.content.current.style.transition = '';
                this.slides.style.setProperty('--slider-drag-offset', offset);

                this.moveToSlide(slide);
            };
            
            this.slides.addEventListener('touchstart', onStart);
            document.addEventListener('touchmove', onMove);
            document.addEventListener('touchend', onStop);
        }
    };

    onSlideWatch = (id, box) => {
        const slide = this.items.find(slide => slide.id === id);

        if (slide === undefined) return;

        const {imagePosition} = slide;

        this.setState({
            openingSlide: {
                id,
                groupId: slide.groupId,
                box,
                imagePosition: Array.isArray(imagePosition) && typeof imagePosition[0] === 'number' && typeof imagePosition[1] === 'number'
                    ? imagePosition
                    : [50, 50],
            },
        });
    };

    renderOpeningSlide() {
        const {openingSlide} = this.state;
        if (openingSlide === null) return null;

        const {id, box} = openingSlide;
        const slide = this.items.find(slide => slide.id === id);
        if (slide === undefined) return null;

        return (
            <Slide
                ref={this.onOpeningSlideRef}
                id={slide.id}
                link={`/works/${slide.groupId}/${slide.id}`}
                image={slide.image}
                imagePosition={slide.imagePosition}
                mobileImagePosition={slide.mobileImagePosition}
                title={slide.title}
                watchButtonText={this.props.watchButtonText}
                animateFrom={box}
                onWatch={this.onSlideWatch}
            />
        );
    }

    render() {
        const {items} = this;
        const {watchButtonText} = this.props;

        return (            
            <div className={styles.slider} 
                 ref={this.slider}
            >
                <div className={styles.outer}
                     ref={this.onSlidesRef}
                >
                    <div className={styles.inner}>
                        <div className={styles.content}
                             ref={this.content}
                        >
                            {items.map((slide, index) => slide.image !== undefined && (
                                <div className={cn(styles.slide, {
                                         [styles.current]: index === this.state.sliderIndex,
                                     })}
                                     key={slide.id}
                                >
                                    <Slide
                                        id={slide.id}
                                        link={`/works/${slide.groupId}/${slide.id}`}
                                        image={slide.image}
                                        imagePosition={slide.imagePosition}
                                        mobileImagePosition={slide.mobileImagePosition}
                                        title={slide.title}
                                        watchButtonText={watchButtonText}
                                        isHidden={this.state.openingSlide !== null && slide.id === this.state.openingSlide.id}
                                        onWatch={this.onSlideWatch}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <PointerArea type="prev"
                             className={cn(styles.prev)}
                             onClick={this.onPrevClick}
                >
                </PointerArea>
                <PointerArea type="next"
                             className={cn(styles.next)}
                             onClick={this.onNextClick}
                >
                </PointerArea>
                <Dots
                    currentSlideIndex={this.state.sliderIndex}
                    items={this.props.groups}
                    onSelect={this.moveToSlide}
                />
                {this.renderOpeningSlide()}
            </div>
        );
    }
}