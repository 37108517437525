import React from 'react';
import DocumentTitle from 'react-document-title';
import content from '../../content/index';
import Tagline from '../../components/Tagline';
import styles from './index.module.css';
import Link from 'react-router-dom/es/Link';
import Popup from '../../components/Popup';
import YoutubePlayer from '../../components/YoutubePlayer';
import LinkBG from '../../components/LinkBG';
import Background from '../../components/Background';
import cn from 'classnames';

const page = content.pages.index;

export default class IndexPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isVideoShown: false,
            isHiddenBgStatic: false,
        };
    }
    
    hideBgStatic =  () => this.setState({isHiddenBgStatic: true});
    openPopup = () => this.setState({isVideoShown: true});
    closePopup = () => this.setState({isVideoShown: false});

    render() {
        const backgroundSettings = {
            playlist: page.content.mainBgVideo.videoKey,
            autohide: 1,
            autoplay: 0,
            controls: 0,
            disablekb: 1,
            enablejsapi: 1,
            fs: 0,
            loop: 1,
            playsinline: 1,
            iv_load_policy: 3,
            rel: 0,
            showinfo: 0,
        };
        const popupSettings = {
            playlist: page.content.mainBgVideo.videoKey,
            autoplay: 0,
            autohide: 1,
            modestbranding: 0,
            rel: 0,
            showinfo: 0,
            controls: 1,
            disablekb: 1,
            enablejsapi: 1,
            iv_load_policy: 3,
            loop: 0,
        };

        return (
            <div className={styles.index}>
                <DocumentTitle title={page.title} />
                <div className={styles.background}>
                    <div className={styles.background__desktop}>
                        <YoutubePlayer 
                            id={'bg_youtube_player'}
                            videoKey={page.content.mainBgVideo.videoKey}
                            videoOption={backgroundSettings}
                            className={styles.video}
                            hideBgStatic={this.hideBgStatic}
                        />
                    </div>
                    <div className={
                            cn(styles.background__mobile, {
                                [styles.background__mobile_hidden]: this.state.isHiddenBgStatic
                            }
                        )}
                    >
                        <Background
                            image={page.content.backgroundImage}
                            className={styles.backgroundLighten}
                        />
                    </div>
                </div>
                <Tagline
                    content={page.content.tagline}
                    disableAnimation
                />
                <div className={styles.showreelButtonWrapper}>
                    <LinkBG
                        to="#"
                        className={styles.showreelButton}
                        maxOffset={0.05}
                        onClick={this.openPopup}
                        alwaysShown
                    >{page.content.firstButton.text}</LinkBG>
                </div>
                <Link
                    className={styles.worksButton}
                    to={page.content.secondButton.path}
                >{page.content.secondButton.text}</Link>
                <Popup
                    isOpen={this.state.isVideoShown}
                    onClose={this.closePopup}
                >
                    <YoutubePlayer  
                        id={'popup_youtube_player'} 
                        videoKey={page.content.firstButton.videoKey}
                        videoOption={popupSettings} 
                        className={`${styles.video} ${styles.videoShowreel}`}
                    />
                </Popup>
            </div>
        );
    }
}
