import React from 'react';
import PropTypes from 'prop-types';
import kute from 'kute.js';
import 'kute.js/kute-svg.js';
import './liquid.css';
import data from '../../content';

const animations = [
    {
        from: 'M459.06-656.689c-27.047-28.397-9.875-222.002-87.5-222 c-64.765,0.001-112.618,263.421-167.5,246C124.063-658.085,84.261-849.133,3.481-849.133 c-223.695,0-177.919,469.313-109.421,602.444c126.552,245.958,363.025,42.085,464,3c105.408-40.8,358.055,223.438,488,87 c91.376-95.944,123.897-654.729-108.408-707.813C601.554-895.601,538.656-573.118,459.06-656.689z',
        to: 'M459.06-32.689c-27.047-28.397-9.875-222.002-87.5-222-64.765.001-112.618 263.421-167.5 246C124.063-34.085 84.261-225.133 3.481-225.133c-223.695 0-108.867 775.767-40.37 908.897 126.552 245.958 300.779 3.814 401.753-35.271 105.408-40.8 260.959 201.682 390.905 65.244 91.376-95.944 214.188-901.156-18.118-954.24C601.554-271.601 538.656 50.882 459.06-32.689z',
    }, {
        from: 'M780.181-370.628c19.212,258.323,175.002,296.32,383.075,296.32 c208.055,0,305.976-145.067,305.976-324.042c0-178.957-97.921-323.715-305.976-324.025 C938.238-722.705,759.573-647.66,780.181-370.628z',
        to: 'M-154.226,291.049c30.227,406.434,275.339,466.217,602.713,466.217 c327.344,0,481.408-228.242,481.408-509.834c0-281.562-154.064-509.318-481.408-509.807 C94.454-262.893-186.649-144.82-154.226,291.049z',
    }, {
        from: 'M371.568-224.691c0.091,1.264,0.862,1.454,1.884,1.454c1.023,0,1.503-0.716,1.503-1.594 s-0.477-1.594-1.507-1.594C372.346-226.424,371.463-226.06,371.568-224.691z',
        to: 'M-7.551,309.623C4.429,470.706-170.027,617.647-54.458,733.217 c115.57,115.576,246.188-70.883,427.626-70.883c149.306,0,300.822,145.093,420.11,54.683 c119.287-90.412-26.455-270.181-26.455-409.41c0-132.685,141.7-266.949,26.455-382.194 C678.033-189.834,535.896-44.222,378.977-44.457C186.539-44.74,60.797-211.088-53.793-77.391 C-168.391,56.306-20.746,132.219-7.551,309.623z',
    }, {
        from: 'M809.467,682.788c-2.488-6.945-3.269-13.953-8.189-18.873c-9.32-9.32-36.141,12.246-38.847,24.424 c-3.114,14.012,13.934,5.484,16.761,11.158c2.971,5.961,3.711,20.417,14.746,22.501c11.2,2.116,38.263-22.104,30.795-33.995 C820.358,681.037,811.762,689.19,809.467,682.788z',
        to: 'M288.317,656.372c135.07,63.832,246.579,152.996,387.493,152.976 c266.896,0,342.119-692.812,206.525-905.912C726.294-341.788,604.284,24.406,482.573-16.349 C354.69-59.171,137.101-255.552-50.738-127.383C-241.414,2.684-282.1,736.973-4.92,800.291 C157.469,837.407,163.806,597.582,288.317,656.372z',
    // }, {
    //     from: 'M781.427,1066.452c-19.478,73.32-205.87,84.432-415.146,81.754 c-203.365-2.604-397.79-16.628-397.79-70.604c0-68.006,143.306-100.16,177.667-147.69c79.267-109.66,41.589-204.92,214.053-204.92 c172.468,0,151.724,107.973,242.075,204.92C652.272,983.548,797.263,1006.835,781.427,1066.452z',
    //     to: 'M-520.978-194.252c43.025-291.214,454.755-335.352,917.034-324.716 c449.225,10.338,878.698,66.045,878.698,280.432c0,270.111-316.555,397.823-392.456,586.612 c-175.098,435.556-91.868,813.919-472.832,813.919c-380.972,0-335.151-428.856-534.732-813.919 C-235.684,135.037-555.963,42.547-520.978-194.252z',
    }
];

const delay = d => new Promise(resolve => setTimeout(resolve, d));

const animate = (node, from, to, duration) => new Promise(resolve => {
    kute.fromTo(document.getElementById('target'),
        { path: from },
        { path: to },
        {
            easing: 'easingQuadIn',
            repeat: 0,
            duration: duration,
            morphIndex: 10,
            complete: resolve,
        }
    ).start();
});

export default class Liquid extends React.Component {
    static propTypes = {
        id: PropTypes.any.isRequired,
        durationIn: PropTypes.number.isRequired,
        durationOut: PropTypes.number.isRequired,
    };
    constructor(props) {
        super(props);
        
        this.state = {
            showText: false,
        };
    }
    static getRandomAnimation() {
        const animationIndex = Math.floor(Math.random() * animations.length);
        return animations[animationIndex];
    }

    async animate(opposite = false) {
        const duration = !opposite ? (this.props.durationIn) : this.props.durationOut;

        const animation = Liquid.getRandomAnimation();
        let {from, to} = animation;
        if (opposite) {
            [from, to] = [to, from];
        }
        await animate(
            document.getElementById('target'),
            from,
            to,
            duration,
        );
    }

    navigatedFromSlider(prevProps) {
        return /\/works\/?/.test(prevProps.id) && /\/works\/.+/.test(this.props.id);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id && !this.navigatedFromSlider(prevProps)) {
            setTimeout(() => {
                this.setState({
                    showText: true,
                },function() {
                    setTimeout(() => {
                        this.setState({
                            showText: false,
                        });
                    }, this.props.durationOut);
                });
            }, this.props.durationIn);
            await this.animate();
            await delay(1000);
            await this.animate(true);
        }
    }
    render() {
        const [ww, wh] = [window.innerWidth, window.innerHeight];
        const [sw, sh] = [743, 642]; // svg width and height

        const [wRatio, sRatio] = [ww / wh, sw / sh];

        let sah; // svg absolute height

        if (wRatio > sRatio) {
            sah = ww / sw * sh;
        } else {
            sah = wh;
        }

        const absoluteOffset = wh / 2;
        const offset = absoluteOffset / sah * 100;
        
        return (
            <svg
                viewBox="0 0 743 642"
                preserveAspectRatio="xMidYMin slice"
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 1001,
                    height: '100%',
                    width: '100%',
                    pointerEvents: 'none',
                }}
            >
                <mask id='mask-1' x='0' y='0' width={sw} height={sh}>
                    <path 
                        id="target"
                        fill="#fff"
                        d=""
                    />
                </mask>
                <g mask='url(#mask-1)'>
                    <rect id='main-rect' x='0' y='0' width={sw} height={sh} fill='#c6ff00' />
                    <text id='text' x='50%' y={`${offset}%`} textAnchor='middle' fill='#000'>{data.liquidAnimationText.title}</text>
                    <text id='text2' x='50%' y={`${offset + 5}%`} textAnchor='middle' fill='#000'>{data.liquidAnimationText.text}</text>
                </g>
            </svg>
        )
    }
}
