import characterDesign from './character-design';
import locationsAndEnvironments from './locations-and-environments';
import hardsurface from './hardsurface';
import animations from './animations';


export default {
    works: {
        titlePrefix: 'Black Polygon - Works - ',
        watchButtonText: 'Watch',
        closeButtonText: 'Close',
        nextButtonText: 'Next',
        items: [
            characterDesign,
            locationsAndEnvironments,
            hardsurface,
            animations,
        ],
    },
};
