import React from 'react';
import styles from './logo.module.css';
import logo from './images/logo.svg';
import mobileLogo from './images/logo-mobile.png';
import Link from '../../components/Link';

export default class Logo extends React.Component {
    render() {
        return (
            <Link
                to="/"
                className={styles.logo}
            >
                <img
                    src={logo}
                    alt="Black Polygon logo"
                    className={styles.desktopImage}
                />
                <img
                    src={mobileLogo}
                    alt="Black Polygon logo"
                    className={styles.mobileImage}
                />
            </Link>
        );
    }
}
