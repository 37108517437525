import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import styles from './wrapper.module.css';
import SiteMap from '../../components/SiteMap';
import Menu from '../../components/Menu';
import Hamburger from '../../components/Hamburger';
import Logo from '../../components/Logo';
import content from '../../content';
import {PopupInjector} from '../Popup';
import cn from 'classnames';

class Wrapper extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    };

    state = {};
    
    onButtonClick = () => {
        this.setState({isHamburgerOpen: !this.state.isHamburgerOpen});
    };

    closeSiteMap = () => {
        if (this.state.isHamburgerOpen === true) {
            this.setState({
                isHamburgerOpen: false,
            });
        }
    };

    render() {
        return (
            <div className={styles.wrapper}>
                <div className={cn(styles.logo, 'detailWorksLogo')}>
                    <Logo />
                </div>
                <div className={styles.menu}>
                    <Menu items={content.secondaryMenu.items} />
                </div>
                <div className={styles.hamburger}>
                    <Hamburger
                        isOpen={this.state.isHamburgerOpen}
                        onClick={this.onButtonClick}
                        globalClass={'detailWorksHamburger'}
                    />
                </div>
                <div className={styles.siteMap}>
                    <SiteMap
                        tagline={content.menu.tagline}
                        items={content.menu.items}
                        isOpen={this.state.isHamburgerOpen}
                        onItemClick={this.closeSiteMap}
                    />
                </div>
                <div className={styles.content}>
                    {this.props.children}
                </div>
                <PopupInjector />
            </div>
        );
    }
}

export default withRouter(Wrapper);
