import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import styles from '../detail.module.css';
import {PointerArea} from '../../../components/Pointer';

export default class NextWorkLink extends React.Component {
    static propTypes = {
        link: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    };

    render() {
        return (
            <Link className={styles.link}
                  to={this.props.link}
            >
                <PointerArea
                    type="nextWork"
                    className={styles.next}
                >
                </PointerArea>
                <PointerArea
                    type="nextWorkBlackArrow"
                    className={styles.inner}
                >
                    <span className={styles.btnText}>{this.props.text}</span>
                </PointerArea>
            </Link>
        );
    }
}
