/*global google*/

import React from 'react';
import PropTypes from 'prop-types';
import styles from '../contacts.module.css';


let scriptPromise = null;
function loadScript(url, callback) {
    if (scriptPromise !== null) {
        scriptPromise.then(callback);
        return;
    }
    scriptPromise = new Promise((resolve, reject) => {
        var head = document.querySelector('head');
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.onload = resolve;
        script.onerror = reject;
        head.appendChild(script);
    }).then(callback);
}

const instances = new Set();

export default class Map extends React.Component {
    static propTypes = {
        apiKey: PropTypes.string.isRequired,
        options: PropTypes.any.isRequired,
        styles: PropTypes.any.isRequired,
        hidden: PropTypes.bool,
    };
    static defaultProps = {
        hidden: false,
    };

    mapRef = React.createRef();
    map = null;
    listener = null;

    componentDidMount() {
        instances.add(this);

        const url = `https://maps.googleapis.com/maps/api/js?key=${this.props.apiKey}`;

        loadScript(url, () => {
            this.map = new google.maps.Map(this.mapRef.current, this.props.options);

            this.map.setOptions({
                disableDefaultUI: true,
                styles: this.props.styles,
            });

            if (this.props.hidden) this.useSyncWorkaround();
        });
    }

    componentWillUnmount() {
        instances.delete(this);
    }

    useSyncWorkaround = () => {
        for (const instance of instances) {
            if (instance === this) continue;
            google.maps.event.removeListener(instance.listener);
        }
        this.listener = google.maps.event.addListener(this.map, 'bounds_changed', () => {
            const [center, zoom] = [
                this.map.getCenter(),
                this.map.getZoom(),
            ];

            for (const instance of instances) {
                if (instance === this) continue;
                if (instance.map === null) continue;

                instance.map.setCenter(center);
                instance.map.setZoom(zoom);
            }
        });
    }

    render() {
        return (
            <div
                className={styles.map}
                ref={this.mapRef}
            />
        );
    }
}
