import React from 'react';
import PropTypes from 'prop-types';
import ReactYoutube from 'react-youtube';

export default class YoutubePlayer extends React.Component {
    static propTypes = {
        videoKey: PropTypes.string.isRequired,
        videoOption: PropTypes.object.isRequired,
        id: PropTypes.string,
        className: PropTypes.string,
        hideBgStatic: PropTypes.func,
    };

    render() {
        const {id, videoKey, videoOption, className, hideBgStatic} = this.props;
        
        return (
            <ReactYoutube
                id={id}
                videoId={videoKey}
                opts={{
                    playerVars: videoOption,
                }}
                onReady={this._onReady}
                onStateChange={(event) => this._onStateChange(event, hideBgStatic)}
                className={className}
            />
        );
    }

    _onReady(event) {
        if (this.id === 'bg_youtube_player') {
            var e = window.innerWidth + 150,
            a = window.innerHeight;

            try {
                if (16 / 9 < e / a) {
                    event.target.setSize(e, e / 16 * 9);
                    event.target.a.style.left = '0px';
                } else {
                    event.target.setSize(a / 9 * 16, a);
                    event.target.a.style.left = -(event.target.a.outerWidth() - e) / 2;
                }
            } catch (e) {
                console.warn(e);
            }
            event.target.setSize(e, e / 16 * 9);
            event.target.mute();
        }
    }

    _onStateChange(event, hideBgStatic) {
        if(this.props.id === 'bg_youtube_player') {
            try {
                if(event.target.getPlayerState() === 1) {
                    hideBgStatic();
                }
            } catch (e) {
                console.warn(e);
            }
        }
    }
}
