import background from './background.jpg';
import tagline from '../tagline';

export default {
    backgroundImage: background,
    tagline: tagline,
    mainBgVideo: {
        videoKey: 'wmaSqvZcgjU',
    },
    firstButton: {
        text: 'Showreel',
        videoKey: 'wmaSqvZcgjU',
    },
    secondButton: {
        text: 'Works',
        path: '/works',
    },
};
