import ildarAlishevImage from './ildar-alishev.png';
import MaximNesterovImage from './MaximNesterov.png';
import KulyaevImage from './AnatolyKulyaev.png';
import MoskvichevImage from './moskvichev.png';
import Puzikimage from './Puzik.png';

export default [
    {
        id: 'ildar-alishev',
        name: 'Ildar Alishev',
        details: {
            image: ildarAlishevImage,
            name: 'Ildar Alishev',
            post: 'Chief executive officer',
            brief: '<p><b>Worked on Warhold as a lead producer. Have 7+ year in game development and 12 years of experience in IT industry.</b></p>',
            text: `<p>Ildar has experience in working as with Unity as with Unreal Engine, knows pipeline in art, animation development and in game design.</p>

<p></p>`,
        },
    },{
        id: 'maxim-nesterov',
        name: 'Maxim Nesterov',
        details: {
            image: MaximNesterovImage,
            name: 'Maxim Nesterov',
            post: 'Business Development Director',
            brief: '<p><b>As a business development director worked in IT industry for 10 years. Have a lot of experience in game development</b></p>',
            text: `<p>Maxim has strong knowledge in business development and marketing. His main goal is to make strong and fruitfull relationship with our future clients.</p>

<p></p>`,
        },
    },{
        id: 'anatoly-kulyaev',
        name: 'Anatoly Kulyaev',
        details: {
            image: KulyaevImage,
            name: 'Anatoly Kulyaev',
            post: 'Lead C# Unity Developer',
            brief: '<p><b>Anatoly has finished 5 mobile and 1 PC project on Unity.</b></p>',
            text: `<p>Anatoly has strong knowledge in Unity, C#, in Photon Network, in working with DataBases and in client/server games.</p>

<p></p>`,
        },
    },{
        id: 'anton-moskvichev',
        name: 'Anton Moskvichev',
        details: {
            image: MoskvichevImage,
            name: 'Anton Moskvichev',
            post: 'Lead 3d/2d Artist',
            brief: '<p><b>Has a lot of experience in working as lead artist. </b></p>',
            text: `<p>Anton knows how to achieve best result in 3d/2d and optimize it to any platform</p>

<p></p>`,
        },
    },
    {
        id: 'denis-puzik',
        name: 'Denis Puzik',
        details: {
            image: Puzikimage,
            name: 'Denis Puzik',
            post: 'Lead C# Backend developer',
            brief: '<p><b>More than 7 years in c# and Unity development.</b></p>',
            text: `<p>Denis has a lot of experience in creating backend parts for many mobile games. Good knowledge in synchronous pvp</p>

<p></p>`,
        },
    },
];
