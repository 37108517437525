import React from 'react';
import {CSSTransition} from 'react-transition-group';

export const timeout = {
    enter: 1500,
    exit: 1500,
};

const Fade = ({ children, ...props }) => (
    <CSSTransition {...props} timeout={timeout} classNames="fade" unmountOnExit mountOnEnter>
        <div style={{position: 'absolute', width: '100%', height: '100%'}}>
            {children}
        </div>
    </CSSTransition>
);

export default Fade;