import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styles from '../aboutDetail.module.css';

export default class PersonDetails extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        post: PropTypes.string,
        brief: PropTypes.string,
        text: PropTypes.string,
    };
    
    setStringToHtml(string) {
        return <div dangerouslySetInnerHTML={{__html: string}}></div>
    }

    render() {
        const {
            name,
            post,
            brief,
            text,
        } = this.props;

        return (
            <Fragment>
                <h2 className={styles.title}>{name}</h2>
                {post !== undefined && <h3 className={styles.top}>{post}</h3>}
                {brief !== undefined && <div className={styles.middle}>{this.setStringToHtml(brief)}</div>}
                {text !== undefined && <div className={styles.bottom}>{this.setStringToHtml(text)}</div>}
            </Fragment>
        );
    }
}
