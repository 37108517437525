import React from 'react';
import PropTypes from 'prop-types';
import styles from '../contacts.module.css';

export default class ContactsInfo extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        country: PropTypes.string,
        city: PropTypes.string,
        street: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
    };

    render() {
        const {
            title,
            country,
            city,
            street,
            phone,
            email,
        } = this.props;

        return (
            <div className={styles.info}>
                <div className={styles.top}>{title}</div>
                {(country || city || street) && (
                    <div className={styles.middle}>
                        {country && <p>{country}</p>}
                        {city && <p>{city}</p>}
                        {street && <p>{street}</p>}
                    </div>
                )}
                {(phone || email) && (
                    <div className={styles.bottom}>
                        {phone && <p><a href={`tel:${phone}`} className={styles.contact}>{phone}</a></p>}
                        {email && <p><a href={`mailto:${email}`} className={styles.contact}>{email}</a></p>}
                    </div>
                )}
            </div>
        );
    }
}
