import React from 'react';
import PropTypes from 'prop-types';
import styles from '../detail.module.css';

export default class Images extends React.Component {
    static propTypes = {
        list: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    };

    render() {
        return (
            <div className={styles.frames}>
                {this.props.list.map((frame, index) => (
                    <div
                        key={index}
                        className={styles.frame}
                        style={{backgroundImage: `url(${frame})`}}
                    />
                ))}
            </div>
        );
    }
}
