import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from '../Link';
import styles from './linkbg.module.css';

export default class LinkBG extends React.Component {
    static propTypes = {
        bgColor: PropTypes.string,
        maxOffset: PropTypes.number,
        alwaysShown: PropTypes.bool,
        hideBGOnMobile: PropTypes.bool,
    };
    static defaultProps = {
        maxOffset: 0.1,
        alwaysShown: false,
        hideBGOnMobile: false,
    };

    translate = {x: 0, y: 0};
    lastTranslate = Date.now();

    onRef = node => {
        this.node = node;
        if (typeof this.props.ref === 'function') this.props.ref(node);
    };
    onBGRef = node => this.bg = node;

    onMouseEnter = () => {
        this.lastTranslate = Date.now();
    };
    onMouseMove = (e) => {
        const {maxOffset} = this.props;
        const {left, top, width, height} = this.node.getBoundingClientRect();

        const x = (e.pageX - left) / width;
        const y = (e.pageY - top) / height;

        this.setTargetTranslate(
            maxOffset * 200 * x - maxOffset * 100,
            maxOffset * 200 * y - maxOffset * 100,
        );

        if (typeof this.props.onMouseMove === 'function') this.props.onMouseMove(e);
    };
    onMouseLeave = (e) => {
        this.targetTranslate = {x: 0, y: 0};
        this.lastTranslate = Date.now();
        if (this.lastTranslate !== null) {
            requestAnimationFrame(this.doTranslate);
        }

        if (typeof this.props.onMouseLeave === 'function') this.props.onMouseLeave(e);
    };

    setTargetTranslate(x, y) {
        this.targetTranslate = {x, y};

        if (this.lastTranslate !== null) {
            requestAnimationFrame(this.doTranslate);
        } else {
            this.doTranslate();
        }
    }
    setTranslate(x, y) {
        this.translate = {x, y};
        this.bg.style.transform = `translate(${x}%, ${y}%)`;
    }
    doTranslate = () => {
        const dt = Date.now() - this.lastTranslate;
        this.lastTranslate = Date.now();

        const step = 100 / 1000 * dt;

        const distanceX = this.targetTranslate.x - this.translate.x;
        const distanceY = this.targetTranslate.y - this.translate.y;

        const stepX = Math.min(
            Math.abs(distanceX),
            step,
        );
        const stepY = Math.min(
            Math.abs(distanceY),
            step,
        );

        const dx = Math.sign(distanceX) * stepX;
        const dy = Math.sign(distanceY) * stepY;

        const x = this.translate.x + dx;
        const y = this.translate.y + dy;

        this.setTranslate(x, y);

        if (x === this.targetTranslate.x && y === this.targetTranslate.y) {
            this.lastTranslate = null;
        } else {
            requestAnimationFrame(this.doTranslate);
        }
    };

    render() {
        const {maxOffset, alwaysShown, hideBGOnMobile, ...props} = this.props;

        return (
            <Link
                {...props}
                innerRef={this.onRef}
                className={cn(this.props.className, styles.wrapper, {
                    [styles.isAlwaysShown]: alwaysShown,
                    [styles.noMobileBG]: hideBGOnMobile,
                })}
                onMouseMove={this.onMouseMove}
                onMouseLeave={this.onMouseLeave}
                onMouseEnter={this.onMouseEnter}
            >
                <div
                    ref={this.onBGRef}
                    className={styles.bg}
                />
                {this.props.children}
            </Link>
        );
    }
}
