import characterDesignAndSculping from './character-design-and-sculping';

export default {
    id: 'character-design',
    name: 'Character design',
    slides: [
        characterDesignAndSculping,
    ],
    firstButton: {
        text: 'Showreel',
        videoKey: '5JDyQsKJ_0c',
    },
};