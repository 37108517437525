import React from 'react';
import PropTypes from 'prop-types';
import {getInstance} from './Pointer';

export default class PointerArea extends React.Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        component: PropTypes.func,
        style: PropTypes.object,
        onMouseMove: PropTypes.func,
        onMouseEnter: PropTypes.func,
    };
    static defaultProps = {
        style: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            cursor: 'none',
        }
    }

    isHovered = false;

    onMove = (e) => {
        const pointer = getInstance();
       
        if ('ontouchstart' in window) {
            this.setPointerPosition();        
        } else if(window.navigator.msPointerEnabled) {
            this.setPointerPosition();
        } else {
            if (pointer !== undefined) {
                pointer.setCoords(e.pageX, e.pageY);
            }
            if (typeof this.props.onMouseMove === 'function') this.props.onMouseMove(e);
        }
    };
    onEnter = (e) => {
        const pointer = getInstance();

        if (pointer !== undefined) {
            pointer.pushType(this.props.type);
        }

        if (typeof this.props.onMouseEnter === 'function') this.props.onMouseEnter(e);

        this.isHovered = true;
    };
    onLeave = (e) => {
        const pointer = getInstance();

        if (pointer !== undefined) {
            pointer.popType(this.props.type);
        }

        if (typeof this.props.onMouseLeave === 'function') this.props.onMouseLeave(e);

        this.isHovered = false;
    };
    setPointerPosition = () => {
        this.setState({
            cursor: 'auto',
        });
    }
    componentWillUnmount() {
        if (this.isHovered) {
            const pointer = getInstance();

            if (pointer !== undefined) {
                pointer.popType(this.props.type);
            }
        }
    }

    render() {
        const props = {
            ...this.props,
            onMouseMove: this.onMove,
            onMouseEnter: this.onEnter,
            onMouseLeave: this.onLeave,
            style: {
                cursor: this.state.cursor,
                ...this.props.style
            },
        };
        
        delete props.component;

        if (typeof this.props.component === 'function') {
            const Component = this.props.component;
            return <Component {...props} />;
        }
        return <div {...props} />;
    }
}
