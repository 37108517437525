import React, {Fragment} from 'react';
import DocumentTitle from 'react-document-title';
import content from '../../content';
import SplitPanes from '../../components/SplitPanes';
import AboutInfo from './components/Info';
import Persons from './components/Persons';

const page = content.pages.about;

export default class AboutPage extends React.Component {
    render() {
        return (
            <Fragment>
                <DocumentTitle title={page.title} />
                <SplitPanes
                    first={(
                        <AboutInfo
                            title={page.content.title}
                            text={page.content.text}
                            logos={page.content.logos}
                        />
                    )}
                    second={(
                        <Persons
                            top={page.content.persons.top}
                            staff={page.content.persons.staff}
                        />
                    )}
                />
            </Fragment>
        );
    }
}
