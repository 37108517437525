import topPersons from './top-persons';
import staffPersons from './staff-persons';

export default {
    title: '17+ Projects Delivered: Development, Porting & Full Art Production',
    text: 'PC, Mobile, Playstation, Nintendo Switch, Xbox One',
    logos: [],
    persons: {
        titlePrefix: 'Black Polygon - About - ',
        top: topPersons,
        staff: staffPersons,
    },
};
