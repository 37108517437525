import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../aboutPage.module.css';
import Link from '../../../components/LinkBG';

const PersonPT = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

export default class Persons extends React.Component {
    static propTypes = {
        top: PropTypes.arrayOf(PersonPT.isRequired).isRequired,
        staff: PropTypes.arrayOf(PersonPT.isRequired).isRequired,
    };

    renderItem(item) {
        return (
            <div
                key={item.id}
                className={styles.item}
            >
                {item.details === undefined ? (
                    <span>{item.name}</span>
                ) : (
                    <Link to={`/about/${item.id}`}>{item.name}</Link>
                )}
            </div>
        );
    }

    render() {
        const {top, staff} = this.props;

        return (
            <div className={cn(styles.inner, styles.second)}>
                <div className={styles.top}>
                    {top.map(this.renderItem)}
                </div>
                <div className={styles.bottom}>
                    {staff.map(this.renderItem)}
                </div>
            </div>
        );
    }
}
