import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './tagline.module.css';

export default class Tagline extends React.Component {
    static propTypes = {
        content: PropTypes.shape({
            firstLine: PropTypes.string.isRequired,
            secondLine: PropTypes.string.isRequired,
            thirdLine: PropTypes.string.isRequired,
        }).isRequired,
        isOpen: PropTypes.bool,
        isInverted: PropTypes.bool,
        disableAnimation: PropTypes.bool,
    };

    static defaultProps = {
        isOpen: false,
        disableAnimation: false,
    };

    ref = React.createRef();

    componentDidMount() {
        if (!this.props.disableAnimation) {
            document.addEventListener('mousemove', this.onMouseMove);
        }
    }
    componentWillUnmount() {
        if (!this.props.disableAnimation) {
            document.removeEventListener('mousemove', this.onMouseMove);
        }
    }

    onMouseMove = (e) => {
        if (this.props.disableAnimation) return;

        const x = e.screenX / window.innerWidth - 0.5;
        const y = e.screenY / window.innerHeight - 0.5;

        this.ref.current.style.setProperty('--px-x', x);
        this.ref.current.style.setProperty('--px-y', y);
    };

    render() {
        const {content, isOpen, isInverted, disableAnimation} = this.props;

        return (
            <div
                ref={this.ref}
                className={cn(styles.wrapper, {
                    [styles.noAnimation]: disableAnimation,
                    [styles.isOpen]: isOpen,
                    [styles.isInverted]: isInverted,
                })}
            >
                <div className={styles.inner}>
                    <span className={styles.top}>{content.firstLine}</span>
                    <span className={styles.middle}>{content.secondLine}</span>
                    <span className={styles.bottom}>{content.thirdLine}</span>
                </div>
            </div>
        );
    }
}
