import background from './background.jpg';
import image1 from './picture1.jpg';
import image2 from './picture2.jpg';
import image3 from './picture3.jpg';

export default {
    id: 'locations-and-environments',
    name: 'Locations & Environments',
    image: image1,
    title: 'Locations &\nEnvironments',
    details: {
        title: 'Props and Environment modelling',
        text: 'We bring the highest quality of Environment and Props modelling, level concep and environment design.',
        background: background,
        images: [image1, image2, image3],
    },
};
