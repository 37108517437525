import background from './background.jpg';
import image1 from './picture1.jpg';
import image2 from './picture2.jpg';
import image3 from './picture3.jpg';
import image4 from './picture4.jpg';
import image5 from './picture5.jpg';
import image6 from './picture6.jpg';
import image7 from './picture7.jpg';
import image8 from './picture8.jpg';
import image9 from './picture9.jpg';


export default {
    id: 'character-design-and-sculping',
    image: background,
    imagePosition: [36, 3],
    mobileImagePosition: [45, 2],
    title: 'Character\ndesign\n& sculpting',
    details: {
        title: 'We create AAA characters for the games that veryone knows in the world.',
        text: 'We do Concept-art, Sculpting, Highpoly, Lowpoly characters for all kinds of games',
        background: background,
        images: [image1, image2, image3, image4, image5, image6, image7, image8, image9],
    },
};
