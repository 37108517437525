import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const delay = d => new Promise(resolve => setTimeout(resolve, d));

export default class Animated extends React.Component {
    static propTypes = {
        id: PropTypes.any.isRequired,
        children: PropTypes.node,
        delay: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.func,
        ]),
        duration: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.func,
        ]).isRequired,
        switchIn: PropTypes.number,
        wrapperClassName: PropTypes.string,
        baseClassName: PropTypes.string,
        inClassName: PropTypes.string,
        outClassName: PropTypes.string,
        onBeforeChange: PropTypes.func
    };
    static defaultProps = {
        delay: 0,
    };

    index = 0;
    queue = [];
    isResolving = false;

    constructor(props) {
        super(props);

        this.state = {
            children: [React.cloneElement(props.children, {
                key: this.index++,
            })],
        };
    }

    pushChild(child) {
        this.queue.push(React.cloneElement(child, {
            key: this.index++,
        }));

        if (!this.isResolving) {
            this.resolveQueue();
        }
    }

    async resolveQueue() {
        this.isResolving = true;
        while (this.queue.length > 0) {
            if (typeof this.props.delay === 'number') {
                await delay(this.props.delay);
            } else {
                await this.props.delay();
            }

            this.setState({
                children: [
                    ...this.state.children,
                    this.queue.shift(),
                ],
            });

            if (typeof this.props.onBeforeChange === 'function') {
                this.props.onBeforeChange();
            }

            if (typeof this.props.duration === 'number') {
                await delay(this.props.duration);
            } else {
                await this.props.duration();
            }

            const children = [...this.state.children];
            children.shift();
            this.setState({children});
        }
        this.isResolving = false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.pushChild(this.props.children);
        }
    }

    render() {
        const {wrapperClassName, baseClassName, inClassName, outClassName} = this.props;

        const [first, second] = this.state.children;

        if (second === undefined) {
            return (
                <div className={wrapperClassName}>
                    {React.cloneElement(first, {
                        className: cn(first.props.className, baseClassName),
                    })}
                </div>
            );
        }
    
        return (
            <div className={wrapperClassName}>
                {React.cloneElement(first, {
                    className: cn(first.props.className, baseClassName, outClassName),
                })}
                {React.cloneElement(second, {
                    className: cn(second.props.className, baseClassName, inClassName),
                })}
            </div>
        );
    }
}
