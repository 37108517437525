import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import content from '../../content';
import Button from '../../components/Button';
import styles from './aboutDetail.module.css';
import SplitPanes from '../../components/SplitPanes';
import PersonDetails from './components/PersonDetails';
import Background from '../../components/Background';

const page = content.pages.about;
const persons = page.content.persons;

export default class AboutDetail extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                personId: PropTypes.string.isRequired,
            }),
        }),
    };

    getPersonId(props = this.props) {
        try {
            return props.match.params.personId;
        } catch (e) {
            return null;
        }
    }
    savePersonId(props) {
        const personId = this.getPersonId(props);

        if (personId !== null) {
            this.lastPersonId = personId;
        }
    }

    constructor(props) {
        super(props);
        this.savePersonId(props);
    }

    componentWillReceiveProps(nextProps) {
        this.savePersonId(nextProps);
    }

    render() {
        const personId = this.lastPersonId;
        const isPersonSelected = person => person.id === personId;
        const person = persons.top.find(isPersonSelected) || persons.staff.find(isPersonSelected);
        const info = person !== undefined ? person.details : undefined;

        return info === undefined ? (
            <Redirect to="/about" />
        ) : (
            <Fragment>
                <DocumentTitle title={`${persons.titlePrefix}${person.details.name}`}/>
                <SplitPanes
                    first={(
                        <Background
                            className={styles.first}
                            image={info.image !== undefined ? info.image : ''}
                        />
                    )}
                    second={(
                        <div className={styles.second}>
                            <div className={styles.btnClose}>
                                <Button
                                    link="/about"
                                    text=""
                                    noBG
                                />
                            </div>
                            <div className={styles.content}>
                                <PersonDetails
                                    name={person.details.name}
                                    post={person.details.post}
                                    brief={person.details.brief}
                                    text={person.details.text}
                                />
                            </div>
                        </div>
                    )}
                />
            </Fragment>
        );
    }
}
