import React from 'react';
import PropTypes from 'prop-types';
import styles from '../detail.module.css';
import Images from './Images';
import Animated from '../../../content/Animated';
import '../animation.css';

export default class WorkDetails extends React.Component {
    static propTypes = {
        images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        onBeforeChange: PropTypes.func.isRequired,
    };

    render() {
        const {images, canAnimation} = this.props;
        
        return (
            <Animated
                id={canAnimation && images}
                duration={1000}
                wrapperClassName="wrapper"
                baseClassName="wrapper-base"
                inClassName="wrapper-in"
                outClassName="wrapper-out"
                onBeforeChange={this.props.onBeforeChange}
            >
                <div className={styles.wrapper}>
                    {canAnimation && images.length !== 0 && <Images list={images} />}
                </div>
            </Animated>
        );
    }
}
