import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../aboutPage.module.css';

const instances = new Set();

export default class AboutInfo extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        logos: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            sliderIndex: 0,
        };
    }

    componentWillMount() {
        instances.add(this);
    }
    componentWillUnmount() {
        instances.delete(this);
    }

    start(e) {
        this.isDragging = true;
        this.initialPosition = e.touches[0].screenX;
        this.slides.style.transition = 'none';
    }
    move(e) {
        if (!this.isDragging) return;

        this.offset = e.touches[0].screenX - this.initialPosition;

        this.slides.style.setProperty('--slider-drag-offset', this.offset);
    }
    stop() {
        if (!this.isDragging) return;
        this.isDragging = false;
        let slide = this.state.sliderIndex - Math.round(this.offset * 1.5 / this.slides.offsetWidth);
        slide = Math.max(0, Math.min(this.props.logos.length - 1, slide));
        this.offset = 0;

        this.slides.style.transition = '';

        this.slides.style.setProperty('--slider-slide-number', slide);
        this.slides.style.setProperty('--slider-drag-offset', this.offset);
        this.setState({sliderIndex: slide});
    }

    onSlidesRef = (slides) => {
        this.slides = slides;

        if (slides !== null) {
            const onStart = (e) => {
                for (const instance of instances.values()) {
                    instance.start(e);
                }
            };

            const onMove = (e) => {
                for (const instance of instances.values()) {
                    instance.move(e);
                }
            };

            const onStop = () => {
                for (const instance of instances.values()) {
                    instance.stop();
                }
            };

            this.slides.addEventListener('touchstart', onStart);
            document.addEventListener('touchmove', onMove);
            document.addEventListener('touchend', onStop);
        }
    };

    render() {
        const {title, text, logos} = this.props;

        return (
            <div className={cn(styles.inner, styles.first)}>
                <div className={styles.title}>{title}</div>
                <div className={styles.text}>{text}</div>
                <div className={styles.labels}
                     ref={this.onSlidesRef}
                >
                    {logos.map((label, index, arr) => (
                        <div className={styles.label}
                             key={index}
                             style={{background: `#0000ff url(${label}) 50% 50%/contain no-repeat`}}
                        >
                        </div>
                    ))}
                </div>
                <div className={styles.dots}>
                    {logos.map((label, index, arr) => (
                        <div className={cn(styles.dot, {[styles.dotActive]: index === this.state.sliderIndex})}
                             key={index}
                        >
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
