import React from 'react';
import PropTypes from 'prop-types';
import styles from './cursor.module.css';

export default class Cursor extends React.Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
    };

    shouldComponentUpdate(nextProps) {
        return this.props.type !== nextProps.type;
    }

    render() {
        return (
            <div
                className={styles.cursor}
                data-type={this.props.type}
            />
        );
    }
}
