import React from 'react';
import {withRouter} from 'react-router-dom';
import './outer.css';
import cn from 'classnames';

class Outer extends React.Component {
    constructor(props){
        super(props);

        this.isWorks = /\/works\/.+/.test(this.props.location.pathname);

        this.state = { 
            isWorksDetail: this.isWorks,
        }
    }

    componentWillReceiveProps(nextProps) {
        let curPath = /\/works\/.+/.test(this.props.location.pathname);
        let nextPath = /\/works\/.+/.test(nextProps.location.pathname);
        
        if(curPath !== nextPath) {
            setTimeout(() => {
                this.setState({
                    isWorksDetail: nextPath,
                });
            }, 1500);
        }
    }

    render() {
        return (
            <div className={cn('outer',{'isWorks': this.state.isWorksDetail})}>
                {React.Children.only(this.props.children)}
            </div>
        );
    }
}

export default withRouter(Outer);