import React from 'react';
import DocumentTitle from 'react-document-title';
import Slider from '../../components/Slider';
import content from '../../content';
import Background from '../../components/Background';
import styles from './works.module.css'

const page = content.pages.works;

export default class WorksPage extends React.Component {
    render() {
        return (
            <div className={styles.worksPage}>
                <DocumentTitle title={page.title} />
                <Background
                    image={page.content.background}
                    className={styles.backgroundLighten}
                />
                <Slider
                    groups={page.content.works.items}
                    watchButtonText={page.content.works.watchButtonText}
                />
            </div>
        );
    }
}
