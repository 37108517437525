import background from './background.png';
import image1 from './picture1.png';

export default {
    id: 'animations',
    image: background,
    imagePosition: [36, 3],
    mobileImagePosition: [45, 2],
    title: 'Rigging\n& Animations',
    details: {
        title: 'We create AAA animations for Unity, Unreal Engine, Cryengine',
        text: 'We can do realistic and cartoonish animations, mocap animations for all major engines, including Unity, Unreal Engine 4, Cryengine. We also specialize on cinematographic animations and scenes. You can check our FPS animation showreel here https://youtu.be/0Rqgxqw60lE',
        background: background,
        images: [image1],
    },
};
