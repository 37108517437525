import background from './background.jpg';
import image1 from './picture1.jpg';
import image2 from './picture2.jpg';
import image3 from './picture3.jpg';
import image4 from './picture4.jpg';
import image5 from './picture5.jpg';

export default {
    id: 'hardsurface',
    image: background,
    imagePosition: [36, 3],
    mobileImagePosition: [45, 2],
    title: 'Hardsurface\nmodelling',
    details: {
        title: 'We bring the highest quality of hardsurface modelling',
        text: 'We can do all kinds of hardsurface for any kinds of game including AAA PC/Console games and lowpoly PBR mobile games.',
        background: background,
        images: [image1, image2, image3, image4, image5],
    },
};
