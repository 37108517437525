import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './dots.module.css';
import LinkBG from '../LinkBG';

export default class Dots extends React.Component {
    static propTypes = {
        currentSlideIndex: PropTypes.number.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            slides: PropTypes.array.isRequired,
        })).isRequired,
        onSelect: PropTypes.func.isRequired,
    };

    render() {
        const {currentSlideIndex, items} = this.props;

        let offset = 0;

        return (
            <div className={styles.dots}>
                {items.map((item, i) => {
                    const firstIndex = offset;
                    const lastIndex = firstIndex + item.slides.length;
                    offset = lastIndex;

                    const isActive = currentSlideIndex >= firstIndex && currentSlideIndex < lastIndex;
                    let isBeforeActive = false;

                    const isLastGroup = i === items.length - 1;
                    if (!isLastGroup) {
                        const next = items[i + 1];
                        const nextFirstIndex = lastIndex + 1;
                        const nextLastIndex = nextFirstIndex + next.slides.length;

                        isBeforeActive = currentSlideIndex >= nextFirstIndex && currentSlideIndex < nextLastIndex;
                    }
                    
                    return (
                        <LinkBG
                            className={cn(styles.dot, {
                                [styles.active]: isActive,
                                [styles.prev]: isBeforeActive,
                            })}
                            type="link"
                            key={item.id}
                            to="#"
                            onClick={() => this.props.onSelect(firstIndex)}
                            alwaysShown={isActive}
                            hideBGOnMobile
                        >
                            {item.name}
                        </LinkBG>
                    );
                })}
            </div>
        );
    }
}
