import React from 'react';
import PropTypes from 'prop-types';
import styles from './hamburger.module.css';
import {PointerArea} from '../Pointer';

export default class Hamburger extends React.Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        globalClass: PropTypes.string,
    };

    render() {
        const {isOpen, globalClass} = this.props;
        return (
            <PointerArea
                type="link"
                className={
                    `${styles.hamburger}
                    ${isOpen ? ` ${styles.isOpen}` : ''} 
                     ${globalClass}
                    ${isOpen ? ` isOpen` : ''}`
                }
                onClick={this.props.onClick}
            >
                <span className={styles.top}></span>
                <span className={styles.bottom}></span>
            </PointerArea>
        );
    }
}
