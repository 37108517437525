import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom'
import DocumentTitle from 'react-document-title';
import styles from './detail.module.css';
import content from '../../content';
import WorkDetails from './components/WorkDetails';
import Info from './components/Info';
import Button from '../../components/Button';
import NextWorkLink from './components/NextWorkLink';
import {timeout} from '../../components/Fade';
import Background from '../../components/Background';
import {CSSTransition} from 'react-transition-group';
import YoutubePlayer from '../../components/YoutubePlayer';
import cn from 'classnames';
import Popup from '../../components/Popup';
import Link from 'react-router-dom/es/Link';
import Tagline from '../../components/Tagline';
import LinkBG from '../../components/LinkBG';

const page = content.pages.works;
const data = page.content.works;

const asyncRAF = cb => new Promise(resolve => requestAnimationFrame(() => {
    cb();
    resolve();
}));
const mix = (f, t, p) => f + (t - f) * p;
const animate = async (duration, onFrame) => {
    const started = Date.now();

    while (Date.now() - started <= duration) {
        await asyncRAF(() => {
            const t = Math.min(1, (Date.now() - started) / duration);
            onFrame(t);
        });
    }
};

export default class WorksDetail extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                groupId: PropTypes.string.isRequired,
                workId: PropTypes.string.isRequired,
            }),
        }),
    };
    
    getGroupId(props = this.props) {
        try {
            return props.match.params.groupId;
        } catch (e) {
            return null;
        }
    }
    saveGroupId(props) {
        const groupId = this.getGroupId(props);

        if (groupId !== null) {
            this.lastGroupId = groupId;
        }
    }
    getWorkId(props = this.props) {
        try {
            return props.match.params.workId;
        } catch (e) {
            return null;
        }
    }
    saveWorkId(props) {
        const workId = this.getWorkId(props);

        if (workId !== null) {
            this.lastWorkId = workId;
        }
    }

    constructor(props) {
        super(props);
        this.saveGroupId(props);
        this.saveWorkId(props);
        this.state = { canAnimation: false };
        this.scrollElem = React.createRef();
        this.state = {
            isVideoShown: true,
            isHiddenBgStatic: false,
        };
    }

    componentDidMount() {
        setTimeout(()=> {
            this.setState({canAnimation: true});
        }, timeout.exit);
    }

    componentWillReceiveProps(nextProps) {
        this.saveWorkId(nextProps);
        this.saveGroupId(nextProps);
    }

    scrollToTop = () => {
        const node = this.scrollElem.current;

        const from = node.scrollTop;
        const to = 0;

        animate(700, t => {
            node.scrollTop = mix(from, to, t);
        });
    };

    render() {
        const backgroundSettings = {
            playlist: page.content.mainBgVideo.videoKey,
            autohide: 1,
            autoplay: 0,
            controls: 0,
            disablekb: 1,
            enablejsapi: 1,
            fs: 0,
            loop: 1,
            playsinline: 1,
            iv_load_policy: 3,
            rel: 0,
            showinfo: 0,
        };
        const popupSettings = {
            playlist: page.content.mainBgVideo.videoKey,
            autoplay: 0,
            autohide: 1,
            modestbranding: 0,
            rel: 0,
            showinfo: 0,
            controls: 1,
            disablekb: 1,
            enablejsapi: 1,
            iv_load_policy: 3,
            loop: 0,
        };

        return (
            <div className={styles.index}>
                <DocumentTitle title={page.title} />
                <div className={styles.background}>
                    <div className={styles.background__desktop}>
                        <YoutubePlayer 
                            id={'bg_youtube_player'}
                            videoKey={page.content.mainBgVideo.videoKey}
                            videoOption={backgroundSettings}
                            className={styles.video}
                            hideBgStatic={this.hideBgStatic}
                        />
                    </div>
                    <div className={
                            cn(styles.background__mobile, {
                                [styles.background__mobile_hidden]: this.state.isHiddenBgStatic
                            }
                        )}
                    >
                        <Background
                            image={page.content.backgroundImage}
                            className={styles.backgroundLighten}
                        />
                    </div>
                </div>
                <Tagline
                    content={page.content.tagline}
                    disableAnimation
                />
                <div className={styles.showreelButtonWrapper}>
                    <LinkBG
                        to="#"
                        className={styles.showreelButton}
                        maxOffset={0.05}
                        onClick={this.openPopup}
                        alwaysShown
                    >{page.content.firstButton.text}</LinkBG>
                </div>
                <Link
                    className={styles.worksButton}
                    to={page.content.secondButton.path}
                >{page.content.secondButton.text}</Link>
                <Popup
                    isOpen={this.state.isVideoShown}
                    onClose={this.closePopup}
                >
                    <YoutubePlayer  
                        id={'popup_youtube_player'} 
                        videoKey={page.content.firstButton.videoKey}
                        videoOption={popupSettings} 
                        className={`${styles.video} ${styles.videoShowreel}`}
                    />
                </Popup>
            </div>
        );
    }


    setStringToHtml(string) {
        return <div dangerouslySetInnerHTML={{__html: string}}></div>
    }

    getData() {
        const workId = this.lastWorkId;
        const groupId = this.lastGroupId;

        const groupIndex = data.items.findIndex(group => group.id === groupId);

        if (groupIndex === -1) return {group: null};
        const group = data.items[groupIndex];

        const itemIndex = group.slides.findIndex(work => work.id === workId);
        if (itemIndex === -1) return {
            group,
            item: null,
        };
        const item = group.slides[itemIndex];

        let nextGroupIndex = groupIndex;
        let nextItemIndex = itemIndex + 1;

        if (nextItemIndex === group.slides.length) {
            nextGroupIndex = (groupIndex + 1) % data.items.length;
            nextItemIndex = 0;
        }

        const nextGroup = data.items[nextGroupIndex];
        const nextItem = nextGroup.slides[nextItemIndex];

        return {
            group,
            item,
            nextGroup,
            nextItem,
        };
    }

    render() {
        const {group, item, nextGroup, nextItem} = this.getData();
        if (group === null || item === null) {
            return (
                <Redirect to="/works" />
            );
        }

        const details = item.details;

        return (
            <div className={styles.detail}>
                <DocumentTitle title={`${data.titlePrefix}${item.name}`} />
                <Background
                    image={item.image}
                    className={styles.backgroundDark}
                />
                <div className={styles.sideBar}>
                    <div className={styles.first}
                         ref={this.scrollElem}
                    >
                        <Info
                            title={details.title}
                            text={details.text}
                            closeButtonText={data.closeButtonText}
                            canAnimation={this.state.canAnimation}
                        />
                        <WorkDetails
                            images={details.images}
                            canAnimation={this.state.canAnimation}
                            onBeforeChange={this.scrollToTop}
                        />
                    </div>
                    {nextItem !== undefined && (
                        <div className={styles.second}>
                            <NextWorkLink
                                link={`/works/${nextGroup.id}/${nextItem.id}`}
                                text={data.nextButtonText}
                            />
                        </div>
                    )}
                    <CSSTransition
                        in={this.state.canAnimation}
                        timeout={900}
                        classNames="close"
                    > 
                        <div className={styles.close}>
                            <Button
                                link="/works"
                                text={'Close'}
                            />
                        </div>
                        
                    </CSSTransition> 
                </div>
            </div>
        );
    }
}