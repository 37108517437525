import React, { Component } from 'react';
import {Router, Route} from 'react-router-dom';
import history from './history';
import IndexPage from './pages/index';
import AboutPage from './pages/about';
import ContactsPage from './pages/contacts';
import WorksPage from './pages/works';
import WorksDetail from './pages/worksDetail';
import AboutDetail from './pages/aboutDetail';
import styles from './app.module.css';
import './fonts/fonts.css';
import {Pointer} from './components/Pointer';
import {PointerArea} from './components/Pointer';
import Wrapper from './components/Wrapper';
import Fade, {timeout} from './components/Fade';
import Liquid from './components/Liquid'
import Outer from './components/Outer'

const Animated = (Component) => (props) => (
    <Fade in={props.match != null}><Component {...props} /></Fade>
);

const AnimatedIndexPage = Animated(IndexPage);
const AnimatedAboutPage = Animated(AboutPage);
const AnimatedAboutDetail = Animated(AboutDetail);
const AnimatedContactsPage = Animated(ContactsPage);
const AnimatedWorksPage = Animated(WorksPage);
const AnimatedWorksDetail = Animated(WorksDetail);

class App extends Component {
  render() {
    return (
        <Router history={history}>
            <Route
                render={({location}) => (
                    <PointerArea
                        type="default"
                        className={`App ${styles.App}`}
                    >
                        <Pointer />
                        <Outer>
                            <Wrapper>
                                <Route exact path="/">{AnimatedIndexPage}</Route>
                                <Route exact path="/about">{AnimatedAboutPage}</Route>
                                <Route exact path="/about/:personId">{AnimatedAboutDetail}</Route>
                                <Route exact path="/contacts">{AnimatedContactsPage}</Route>
                                <Route exact path="/works">{AnimatedWorksPage}</Route>
                                <Route exact path="/works/:groupId/:workId">{AnimatedWorksDetail}</Route>
                            </Wrapper>
                        </Outer>
                        <Liquid id={location.pathname} durationIn={timeout.enter} durationOut={timeout.exit} />
                    </PointerArea>
                )}
            />
        </Router>
    );
  }
}

export default App;
