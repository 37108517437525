import React from 'react';
import PropTypes from 'prop-types';
import styles from '../detail.module.css';
import Animated from '../../../content/Animated';
import Logo from '../../../components/Logo';
import '../animation.css';
import '../isWorks.css';

export default class Info extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        canAnimation: PropTypes.bool,
    };
    
    setStringToHtml(string) {
        return <div dangerouslySetInnerHTML={{__html: string}}></div>
    }

    render() {
        const {title, text, canAnimation} = this.props;
        
        return (
            <div className={styles.outer}>
                <div className={styles.info}>
                <div className='mobileLogo'>
                    <Logo />
                </div>
                    <Animated
                        id={canAnimation && title}
                        duration={1000}
                        wrapperClassName="title"
                        baseClassName="title-base"
                        inClassName="title-in"
                        outClassName="title-out"
                    >
                        <div className={styles.title}>{canAnimation && title}</div>
                    </Animated>
                    <Animated
                        id={canAnimation && text}
                        duration={1000}
                        wrapperClassName="text"
                        baseClassName="text-base"
                        inClassName="text-in"
                        outClassName="text-out"
                    >
                        <div className={styles.text}>{canAnimation && this.setStringToHtml(text)}</div>
                    </Animated>      
                </div>
            </div>
        );
    }
}