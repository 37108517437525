import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './slide.module.css';
import Button from '../Button';
import Link from '../Link';

const isMultiple = value => value.every(val => Array.isArray(val));
const asyncRequestAnimationFrame = () => new Promise(resolve => requestAnimationFrame(resolve));
const mix = (a, b, f) => a + (b - a) * f;
const animate = async (node, duration, animation, easingFunction) => {
    let t = 0;
    const startTime = Date.now();

    while (t <= duration) {
        await asyncRequestAnimationFrame();

        if (node === null) return;

        t = Date.now() - startTime;

        const progress = easingFunction(Math.min(1, 1 / duration * t));

        for (const [property, {from, to, units}] of Object.entries(animation)) {
            if (isMultiple([from, to, units])) {
                const result = [];
                for (let i = 0; i < from.length; i++) {
                    result.push(`${mix(from[i], to[i], progress)}${units[i]}`);
                }
                node.style[property] = result.join(' ');
            } else {
                node.style[property] = `${mix(from, to, progress)}${units}`;
            }
        }
    }
};

export default class Slide extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        imagePosition: PropTypes.arrayOf(PropTypes.number).isRequired,
        mobileImagePosition: PropTypes.arrayOf(PropTypes.number).isRequired,
        title: PropTypes.string.isRequired,
        watchButtonText: PropTypes.string.isRequired,
        isHidden: PropTypes.bool,
        animateFrom: PropTypes.shape({
            left: PropTypes.number.isRequired,
            top: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired,
        }),
        onWatch: PropTypes.func.isRequired,
    };
    static defaultProps = {
        imagePosition: [50, 50],
        mobileImagePosition: [50, 50],
    };

    wrapper = React.createRef();
    image = React.createRef();
    mobileImage = React.createRef();
    layout = React.createRef();
    button = React.createRef();
    title = React.createRef();

    componentDidMount() {
        if (this.props.animateFrom !== undefined) {
            this.animateOpeningSlide(this.wrapper.current);
        }
    }

    onWatch = (e) => {
        this.props.onWatch(
            this.props.id,
            this.wrapper.current.getBoundingClientRect(),
        );
    };

    animateOpeningSlide = async (node) => {
        if (node === null) return null;

        const {animateFrom: box, imagePosition, mobileImagePosition} = this.props;
        const {left, top} = box;

        const width = box.width / window.innerWidth * 100;
        const height = box.height / window.innerHeight * 100;

        const splitAnimation = (animation) => Object.entries(animation).reduce(([a1, a2], [property, {from, to, units}]) => {
            const middle = isMultiple([from, to, units])
                ? [from[0] + (to[0] - from[0]) / 2, from[1] + (to[1] - from[1]) / 2]
                : from + (to - from) / 2;
            a1[property] = {from, to: middle, units};
            a2[property] = {from: middle, to, units};

            return [a1, a2];
        }, [{}, {}]);

        const wrapperAnimations = splitAnimation({
            left: {
                from: left,
                to: 0,
                units: 'px',
            },
            top: {
                from: top,
                to: 0,
                units: 'px',
            },
            width: {
                from: width,
                to: 100,
                units: '%',
            },
            height: {
                from: height,
                to: 100,
                units: '%',
            },
        });

        const img = new Image();
        img.src = this.props.image;

        const imageRatio = img.width / img.height;
        const targetRatio = window.innerWidth / window.innerHeight;

        let imageSize;
        {
            const imageRect = this.image.current.getBoundingClientRect();
            const imageRatio = imageRect.width / imageRect.height;
            const bgRatio = img.width / img.height;

            if (imageRatio > bgRatio) {
                imageSize = 100;
            } else {
                imageSize = ((imageRatio - bgRatio) * this.image.current.innerHeight / this.image.current.innerWidth + 1) * 100;
            }
        }

        const backgroundAnimations = splitAnimation({
            backgroundSize: {
                from: imageSize,
                to: imageRatio <= targetRatio
                    ? 100
                    : ((imageRatio - targetRatio) * window.innerHeight / window.innerWidth + 1) * 100,
                units: '%',
            },
            backgroundPosition: {
                from: imagePosition,
                to: [50, 50],
                units: ['%', '%'],
            },
        });

        let mobileImageSize;
        {
            const imageRect = this.mobileImage.current.getBoundingClientRect();
            const imageRatio = imageRect.width / imageRect.height;
            const bgRatio = img.width / img.height;

            if (imageRatio > bgRatio) {
                mobileImageSize = 100;
            } else {
                mobileImageSize = ((imageRatio - bgRatio) * this.image.current.innerHeight / this.image.current.innerWidth + 1) * 100;
            }
        }

        const mobileBackgroundAnimations = splitAnimation({
            backgroundSize: {
                from: mobileImageSize,
                to: imageRatio <= targetRatio
                    ? 100
                    : ((imageRatio - targetRatio) * window.innerHeight / window.innerWidth + 1) * 100,
                units: '%',
            },
            backgroundPosition: {
                from: mobileImagePosition,
                to: [50, 50],
                units: ['%', '%'],
            },
        });
        const layoutAnimation = {
            opacity: {
                from: 0,
                to: 0.5,
                units: '',
            },
        };
        const titleAnimation = {
            opacity: {
                from: 1,
                to: 0,
                units: '',
            },
            marginRight: {
                from: 0,
                to: 5,
                units: 'vw',
            },
        };
        const buttonAnimation = {
            opacity: {
                from: 1,
                to: 0,
                units: '',
            },
            marginTop: {
                from: 0,
                to: -15,
                units: 'vh',
            },
            marginRight: {
                from: 0,
                to: 10,
                units: 'vw',
            },
        };

        const easeOutQuad = function (t) { return t*(2-t) };
        const linear = function (t) { return t };
        const easeInQuad = function (t) { return t*t };

        await Promise.all([
            animate(this.wrapper.current, 1000, wrapperAnimations[0], easeOutQuad),
            animate(this.image.current, 1000, backgroundAnimations[0], easeOutQuad),
            animate(this.mobileImage.current, 1000, mobileBackgroundAnimations[0], easeOutQuad),
            animate(this.title.current, 700, titleAnimation, linear),
            animate(this.button.current, 500, buttonAnimation, linear),
        ]);
        await Promise.all([
            animate(this.wrapper.current, 500, wrapperAnimations[1], easeInQuad),
            animate(this.image.current, 500, backgroundAnimations[1], easeInQuad),
            animate(this.mobileImage.current, 500, mobileBackgroundAnimations[1], easeInQuad),
            animate(this.layout.current, 500, layoutAnimation, linear),
        ]);
    };

    render() {
        const {
            link,
            image,
            imageSize,
            imagePosition,
            mobileImageSize,
            mobileImagePosition,
            title,
            watchButtonText,
            animateFrom,
        } = this.props;

        const animated = animateFrom !== undefined;

        return (
            <div
                ref={this.wrapper}
                className={cn(styles.wrapper, {
                    [styles.animated]: animated,
                    [styles.hidden]: this.props.isHidden,
                })}
            >
                <div className={styles.inner}>
                    <Link
                        to={link}
                        onClick={this.onWatch}
                    >
                        <div
                            ref={this.image}
                            className={styles.image}
                            style={{
                                backgroundImage: `url(${image})`,
                                backgroundSize: typeof imageSize === 'number' ? `${imageSize}%` : 'cover',
                                backgroundPosition: imagePosition.map(p => `${p}%`).join(' '),
                            }}
                        />
                        <div
                            ref={this.mobileImage}
                            className={styles.mobileImage}
                            style={{
                                backgroundImage: `url(${image})`,
                                backgroundSize: `${mobileImageSize}%`,
                                backgroundPosition: mobileImagePosition.map(p => `${p}%`).join(' '),
                            }}
                        />
                    </Link>
                    <div
                        ref={this.button}
                        className={styles.button}
                    >
                        <Button
                            link={link}
                            text={watchButtonText}
                            onClick={this.onWatch}
                        />
                    </div>
                    <div
                        ref={this.title}
                        className={styles.title}
                    >{title}</div>
                    <div
                        ref={this.layout}
                        className={styles.layout}
                    />
                </div>
            </div>
        );
    }
}
