import React from 'react';
import DocumentTitle from 'react-document-title';
import styles from './contacts.module.css';
import SplitPanes from '../../components/SplitPanes';
import content from '../../content';
import Map from './components/Map';
import ContactsInfo from './components/Info';

const page = content.pages.contacts;

export default class ContactsPage extends React.Component {
    render() {
        const {map, info} = page.content;

        return (
            <div className={styles.wrapper}>
                <DocumentTitle title={page.title} />
                <SplitPanes
                    first={(
                        <div className={styles.contacts}>
                            <ContactsInfo
                                title={info.title}
                                country={info.country}
                                city={info.city}
                                street={info.street}
                                phone={info.phone}
                                email={info.email}
                            />
                        </div>
                    )}
                    collapsible
                />
            </div>
        );
    }
}