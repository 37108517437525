import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Animated from '../../content/Animated';
import './animation.css';

const loadImage = src => new Promise((rs, rj) => {
    const img = new Image();
    img.onload = rs;
    img.onerror = rj;
    img.src = src;
});

export default class Background extends React.Component {
    static propTypes = {
        image: PropTypes.string.isRequired,
        className: PropTypes.string,
        children: PropTypes.node,
    };

    onImageLoad = async () => {
        await loadImage(this.props.image);
    };

    render() {
        const {image, className} = this.props;

        return (
            <Animated
                id={image}
                delay={this.onImageLoad}
                duration={1000}
                wrapperClassName="background"
                baseClassName={cn(className, 'background-base')}
                inClassName="background-in"
                outClassName="background-out"
            >
                <div
                    style={{
                        backgroundImage: `url(${image})`,
                    }}
                    className={this.props.className}
                />
            </Animated>
        );
    }
}
