import React from 'react';
import Cursor from './Cursor';
import styles from './cursor.module.css';

let instance;

export const getInstance = () => instance;

export default class Pointer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            types: ['default'],
            left: 20000,
            top: 20000,
        };
    }

    onRef = node => this.node = node;

    componentDidMount() {
        if (instance === undefined) {
            instance = this;
        }
    }
    componentWillUnmount() {
        if (instance === this.node) {
            instance = undefined;
        }
    }

    setCoords(left, top) {  
        const state = {left, top};
        
        this.setState(state);

        this.node.style.transform = this.getTransform(state);
    }
    pushType(type) {
        this.setState(state => ({
            types: [...state.types, type],
        }));
    }
    popType(type) {
        const index = this.state.types.lastIndexOf(type);

        if (index >= 1) {
            this.setState(state => ({
                types: state.types.slice(0, index),
            }));
        }
    }

    getTransform(state = this.state) {
        const {left, top} = state;
        return `translate(${left}px, ${top}px)`;
    }

    render() {
        return (
            <div
                ref={this.onRef}
                className={styles.cursorWrapper}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    zIndex: 1000,
                    pointerEvents: 'none',
                    transform: this.getTransform(),
                }}
            >
                <Cursor type={this.state.types[this.state.types.length - 1]} />
            </div>
        );
    }
}
