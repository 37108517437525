import React from 'react';
import PropTypes from 'prop-types';
import LinkBG from '../LinkBG';
import Link from '../Link';
import styles from './button.module.css';

export default class Button extends React.Component {
    static propTypes = {
        link: PropTypes.string.isRequired,
        text: PropTypes.string,
        onClick: PropTypes.func,
        noBG: PropTypes.bool,
    };
    static defaultProps = {
        noBG: false,
    };

    render() {
        const {link, text, onClick, noBG} = this.props;

        if (noBG) {
            return (
                <Link
                    className={styles.button}
                    to={link}
                    onClick={onClick}
                >
                    <div className={styles.text}>{text}</div>
                </Link>
            );
        }

        return(
            <LinkBG
                maxOffset={0.05}
                className={styles.button}
                to={link}
                onClick={onClick}
                alwaysShown
            >
                <div className={styles.text}>{text}</div>
            </LinkBG>
        );
    }
}