

export default [
    {
        id: 'artem-buravilin',
        name: 'Artem Buravilin',
    },{
        id: 'anastasiya-n',
        name: 'Anastasita Naumenko',
    },{
        id: 'aleksandr-k',
        name: 'Aleksandr Korabelshikov',
    },{
        id: 'vyacheslav-n',
        name: 'Vyacheslav Nalivaiko',
    },
];
