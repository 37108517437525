import React from 'react';
import PropTypes from 'prop-types';

export default class MaskedImage extends React.Component {
    static propTypes = {
        image: PropTypes.string.isRequired,
        className: PropTypes.string,
    };

    render() {
        return (
            <svg className={this.props.className}
                 viewBox="0 0 1464 1080"
                 height="1080"
                 width="1464"
                 xmlnsXlink="http://www.w3.org/1999/xlink"
                 xmlns="http://www.w3.org/2000/svg">

                <clipPath id="clipPolygon">
                    <polygon xmlns="http://www.w3.org/2000/svg"
                             points="470 0,1464 0,1464 1080,0 1080"
                    />
                </clipPath>

                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref={this.props.image}
                       style={{'clipPath': 'url(#clipPolygon)'}}
                />
            </svg>
        );
    }
}
