import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './splitPanes.module.css';
import {PointerArea} from '../Pointer';

export default class SplitPanes extends React.Component {
    static propTypes = {
        first: PropTypes.node,
        second: PropTypes.node,
        collapsible: PropTypes.bool.isRequired,
    };
    static defaultProps = {
        collapsible: false,
    };

    state = {
        isCollapsed: false,
    };

    panes = React.createRef();
    pane = React.createRef();

    componentDidMount() {
        if (!this.props.collapsible) return;

        const panes = this.panes.current;
        const pane = this.pane.current;

        let isDragging = false;
        let initialPosition;
        let offset = 0;

        const onStart = (e) => {
            isDragging = true;
            initialPosition = e.screenX;
            panes.classList.add(styles.catched);
        };

        const onMove = (e) => {
            if (!isDragging) return;

            offset = e.screenX - initialPosition;
            if (!this.state.isCollapsed) {
                offset = Math.max(0, offset);
            }

            panes.style.setProperty('--offset', offset);
        };

        const onStop = () => {
            if (!isDragging) return;
            isDragging = false;

            this.setState({isCollapsed: offset > 100});

            offset = 0;

            panes.classList.remove(styles.catched);

            panes.style.setProperty('--offset', offset);
        };

        pane.addEventListener('mousedown', onStart);
        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onStop);
    };
    
    preScroll(duration) {
        let start = Date.now(),
            panel = this.panes.current,
            panelScrollTop = panel.scrollTop;
        
        
        requestAnimationFrame(function animate() {
            let timePass = Date.now() - start,
                progress = (timePass / duration),
                fixProgress = progress.toFixed(2);
            
            if(timePass > duration) {
                timePass = duration;
            }
            
            panel.scrollTop = panelScrollTop - (panelScrollTop * fixProgress);
                
            if(timePass < duration) {
                requestAnimationFrame(animate);
            }
        });
    }

    render() {
        const {first, second, collapsible} = this.props;

        return (
            <div
                ref={this.panes}
                className={cn(styles.panes, {
                    [styles.collapsed]: this.state.isCollapsed,
                })}
            >
                <div className={cn(styles.pane, styles.first, styles.fixed)}
                     onTouchStartCapture={() => this.preScroll(150)}
                >
                    {first}
                </div>
                <div className={styles.scrollable}>
                    <div className={cn(styles.pane, styles.first)}
                         onTouchStartCapture={() => this.preScroll(150)}
                    >
                        {React.cloneElement(first, {hidden: true})}
                    </div>
                    <div
                        ref={this.pane}
                        className={cn(styles.pane, styles.second)}
                    >
                        {collapsible && (
                            <PointerArea
                                type="link"
                                className={styles.handle}
                            />
                        )}
                        {second}
                    </div>
                </div>
            </div>
        );
    }
}
